import React, {useState, useEffect} from 'react';
import * as Yup from 'yup';
import styled from 'styled-components';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {Input, Pagination} from 'Common/components/Controls';
import {Cell, Table} from 'Common/components/Table/Table';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {AdminPageLayout} from 'Admin/common/styled/StyledComponents';
import {Button, Modal} from 'react-bootstrap';
import Loading from '../../../../Loading/components/Loading';
import {axiosWrapper} from '../../../../Common/services/AxiosWrapper';
import SelectField from '../../../../Common/components/FormFields/SelectField';
import {IObjectResponse} from '../../../../Common/models/IResponse';
import {IHorseAdmin} from '../../../AdminDashboard/models/Horse';
import {CellAlign} from '../../../../Common/components/Table/constants/CellAlign';
import HorseActions from '../../../AdminDashboard/components/Horses/HorseActions';
import ProductActions from '../ProductActions';
import MarketplaceImages from '../MarketplaceImages';

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES_BASE_URL || '';
export interface IMarketplaceAdminProductResponse {
  id: number;
  company: string;
  productName: string;
  price: number;
  description: string;
  link?: string;
  affiliateLink?: string;
  traits?: {
    trait: string;
    isPresent: boolean;
  }[];
  media: [
    {
      id: number;
      type: string; // e.g. "Image"
      url: string;
    }
  ];
}
export interface IMarketplaceAdminProduct {
  id: number;
  company: string;
  productName: string;
  price: number;
  description: string;
  link?: string;
  affiliateLink?: string;
  traits?: string[];
  topProduct?: boolean;
  media: [
    {
      id: number;
      type: string; // e.g. "Image"
      url: string;
    }
  ];
}
export interface IMarketplaceProductPayload {
  company: string;
  productName: string;
  imageUrl: string;
  price: number;
  description: string;
  productLink?: string;
  affiliateLink?: string;
  name: string;
  topProduct?: boolean;
  traits: {
    trait: string;
    isPresent: boolean;
  }[];
}

const mockBehaviorAttributes = [
  {value: 'barking', label: 'Barking'},
  {value: 'chasing', label: 'Chasing'},
  {value: 'dog-fear', label: 'Dog Fear'},
  {value: 'energy', label: 'Energy'},
  {value: 'environmental-fear', label: 'Environmental Fear'},
  {value: 'escaping', label: 'Escaping'},
  {value: 'excitability', label: 'Excitability'},
  {value: 'familiar-dog-aggression', label: 'Familiar Dog Aggression'},
  {value: 'owner-aggression', label: 'Owner Aggression'},
  {value: 'rivalry-aggression', label: 'Rivalry Aggression'},
  {value: 'separation-anxiety', label: 'Separation Anxiety'},
  {value: 'separation-urination', label: 'Separation Urination'},
  {value: 'stranger-aggression', label: 'Stranger Aggression'},
  {value: 'stranger-fear', label: 'Stranger Fear'},
  {value: 'stress-at-veterinarian', label: 'Stress At Veterinarian'},
  {value: 'touch-sensitivity', label: 'Touch Sensitivity'},
  {value: 'trainability', label: 'Trainability'},
  {value: 'unfamiliar-dog-aggression', label: 'Unfamiliar Dog Aggression'},
];

const SearchSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const useMarketplaceProducts = () => {
  const [products, setProducts] = useState<IMarketplaceAdminProduct[]>([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    countPerPage: 10,
    totalItemsCount: 0,
    pageCount: 0,
  });

  const getProducts = async () => {
    setLoading(true);
    try {
      const result = await axiosWrapper.get<IObjectResponse<IMarketplaceAdminProductResponse[]>>(
        'admin/marketplace/products'
      );
      const data = result.data.data;

      setProducts(
        data.map((product: IMarketplaceAdminProductResponse) => {
          return {
            traits: product.traits?.map((trait) => trait.trait),
            ...product,
          } as IMarketplaceAdminProduct;
        })
      );
      setPagination((prev) => ({
        ...prev,
        totalItemsCount: data.length,
        pageCount: Math.ceil(data.length / prev.countPerPage),
      }));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, [pagination.currentPage, pagination.countPerPage]);

  const handlePageSelect = (currentPage: number) => {
    setPagination((prev) => ({...prev, currentPage}));
  };

  const handleCountPerPage = (countPerPage: number) => {
    setPagination((prev) => ({...prev, countPerPage, currentPage: 1}));
  };

  return {
    products,
    loading,
    pagination,
    handlePageSelect,
    handleCountPerPage,
    refresh: getProducts, // expose for external use
  };
};

interface ProductFormProps {
  onClose: () => void;
  mode: 'create' | 'edit';
  initialValues?: Partial<IMarketplaceAdminProduct>;
}

const productFormValidationSchema = Yup.object().shape({
  productName: Yup.string().required('Product Name is required'),
  company: Yup.string().required('Company is required'),
  affiliateLink: Yup.string().required('Affiliate Link is required'),
});

const ProductForm = ({onClose, mode, initialValues}: ProductFormProps) => {
  const isEdit = mode === 'edit';

  const uploadProductMedia = async (productId: number, avatar: any | null, gallery: any[]): Promise<void> => {
    if (!productId || (!avatar && (!gallery || gallery.length === 0))) return;

    const formData = new FormData();
    formData.append('productId', productId.toString());
    if (avatar && avatar.file) {
      formData.append('avatar', avatar.file);
    }

    await axiosWrapper.post(`/admin/marketplace/products/images`, formData);
  };

  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? 'Edit Product' : 'Add Product'}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          company: '',
          productName: '',
          imageUrl: '',
          price: 0,
          description: '',
          productLink: '',
          affiliateLink: '',
          topProduct: false,
          avatar: initialValues?.media?.[0] || null,
          gallery: initialValues?.media || [],
          ...initialValues,
          traits: initialValues?.traits?.map((trait) => trait.name),
        }}
        enableReinitialize
        validationSchema={productFormValidationSchema}
        onSubmit={async (values, {setSubmitting}) => {
          console.log('@@@On Submit: ');
          try {
            let productId = values.id;

            const productPayload: IMarketplaceProductPayload = {
              company: values.company,
              productName: values.productName,
              imageUrl: values.imageUrl,
              price: values.price,
              description: values.description,
              productLink: values.productLink,
              affiliateLink: values.affiliateLink,
              name: values.productName,
              topProduct: values.topProduct,
              traits: values.traits?.map((trait) => ({
                trait: trait,
                isPresent: true,
              })),
            };
            if (isEdit && productId !== undefined) {
              await axiosWrapper.put(`admin/marketplace/products/${productId}`, productPayload);

              const hasMediaToUpload = values.avatar || (values.gallery && values.gallery.length > 0);
              if (hasMediaToUpload) {
                await uploadProductMedia(productId, values.avatar, values.gallery);
              }
            } else {
              const response = await axiosWrapper.post<IObjectResponse<number>>(
                'admin/marketplace/products',
                productPayload
              );
              productId = response.data.data;
              await uploadProductMedia(productId, values.avatar, values.gallery);
            }

            onClose();
          } catch (error) {
            console.error('Failed to save product', error);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({handleSubmit}) => (
          <Form onSubmit={handleSubmit} noValidate>
            <Modal.Body>
              <Field name="productName" as={Input} style={{marginBottom: '4px'}} placeholder="Product Name" />
              <ErrorMessage name="productName">
                {(msg) => <div style={{color: 'red', fontSize: '10px', marginBottom: '16px'}}>{msg}</div>}
              </ErrorMessage>

              <Field name="company" as={Input} style={{marginBottom: '4px'}} placeholder="Company" />
              <ErrorMessage name="productName">
                {(msg) => <div style={{color: 'red', fontSize: '10px', marginBottom: '16px'}}>{msg}</div>}
              </ErrorMessage>

              <Field name="price" as={Input} type="number" placeholder="Price" />
              <Field name="description" as={Input} placeholder="Description" />
              <Field name="productLink" as={Input} placeholder="Product Link" />

              <Field name="affiliateLink" as={Input} style={{marginBottom: '4px'}} placeholder="Affiliate Link" />
              <ErrorMessage name="productName">
                {(msg) => <div style={{color: 'red', fontSize: '10px', marginBottom: '16px'}}>{msg}</div>}
              </ErrorMessage>
              <MarketplaceImages />
              <SelectField name="traits" label="Traits" isMulti={true} options={mockBehaviorAttributes} />
              <div style={{marginBottom: '12px', marginTop: '10px'}}>
                <label>
                  <Field type="checkbox" name="topProduct" /> Top Product
                </label>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit" variant="primary">
                {isEdit ? 'Update' : 'Save'} Product
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const AdminMarketplaceProductsTable = () => {
  const {products, loading, pagination, handlePageSelect, handleCountPerPage, refresh} = useMarketplaceProducts();

  const [modalState, setModalState] = useState<{
    open: boolean;
    mode: 'create' | 'edit';
    product?: IMarketplaceAdminProduct;
  }>({open: false, mode: 'create'});

  const openCreateModal = () => setModalState({open: true, mode: 'create'});
  const openEditModal = (product: IMarketplaceAdminProduct) => {
    const clonedMedia = product.media[0];
    if (clonedMedia?.url) {
      clonedMedia.url = `${IMAGE_BASE_URL}/${clonedMedia.url}`;
    }
    setModalState({
      open: true,
      mode: 'edit',
      product: {
        ...product,
        media: [clonedMedia],
      },
    });
  };
  const closeModal = async () => {
    setModalState({open: false, mode: 'create'});
    await refresh(); // refresh product list after modal closes
  };

  if (loading) return <Loading />;

  return (
    <AdminPageLayout>
      <SearchSection>
        <Input placeholder="Search for products..." />
        <PrimaryButton onClick={openCreateModal}>+ Add Product</PrimaryButton>
      </SearchSection>

      {modalState.open && (
        <ProductForm
          mode={modalState.mode}
          initialValues={modalState.product}
          onClose={closeModal} // refresh triggered here
        />
      )}

      <Table data={products} rowKey="id">
        <Cell<IMarketplaceAdminProduct> dataKey="id" width="10%" header="ID" render={({id}) => `#${id}`} />
        <Cell<IMarketplaceAdminProduct>
          dataKey="media"
          width="20%"
          header=""
          render={({media}) => {
            const image = media?.[0]?.url;
            return image ? <img src={`${IMAGE_BASE_URL}/${image}`} alt="preview" style={{height: 50}} /> : null;
          }}
        />
        <Cell<IMarketplaceAdminProduct>
          dataKey="company"
          width="20%"
          header="Company"
          render={({company}) => company}
        />
        <Cell<IMarketplaceAdminProduct>
          dataKey="productName"
          width="20%"
          header="Product Name"
          render={({productName}) => productName}
        />
        <Cell<IMarketplaceAdminProduct> dataKey="price" width="15%" header="Price" render={({price}) => `$${price}`} />
        <Cell<IMarketplaceAdminProduct>
          dataKey="description"
          width="25%"
          header="Description"
          render={({description}) => description}
        />
        <Cell<IMarketplaceAdminProduct>
          width="10%"
          header="Actions"
          render={(product) => (
            <ProductActions
              product={product}
              editHandler={() => openEditModal(product)}
              deleteHandler={async (id) => {
                try {
                  await axiosWrapper.delete(`/admin/marketplace/products/${id}`);
                  await refresh(); // call the refresh function to reload data
                } catch (err) {
                  console.error('Failed to delete product:', err);
                  alert('Failed to delete product');
                }
              }}
            />
          )}
        />
      </Table>

      <Pagination pagination={pagination} onPageSelect={handlePageSelect} onChangeCountPerPage={handleCountPerPage} />
    </AdminPageLayout>
  );
};

export default AdminMarketplaceProductsTable;
