import React from 'react';
import styled from 'styled-components';
import ImageUploaderField from 'Common/components/FormFields/ImageUploaderField';
import GalleryField from 'Common/components/FormFields/GalleryField';
import {IconName, IProps as IconProps} from 'Icon/components/Icon';

const Avatar = styled.div`
  margin-right: 16px;
`;

const galleryIconProps: IconProps = {name: IconName.InsertPhoto, size: 48};
const galleryVideoIconProps: IconProps = {name: IconName.InsertVideo, size: 48};

interface MarketplaceImagesProps {
  initialAvatar?: any;
  initialGallery?: any[];
}

const MarketplaceImages = ({initialAvatar, initialGallery}: MarketplaceImagesProps) => {
  return (
    <div className="d-flex">
      <Avatar>
        <ImageUploaderField
          name="avatar"
          title="Add product image"
          iconProps={galleryIconProps}
          memoized={true}
          showDownloadButton={true}
        />
      </Avatar>
    </div>
  );
};

export default MarketplaceImages;
